import { IconButton, MenuItem, TextField } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import ClearIcon from "@material-ui/icons/Clear";
import CustomDatePickerRange from "../../app/components/custom.date.picker.range";
import MuiTableHead from "@material-ui/core/TableHead";
import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

const opportunityStageOptions = [
  "Closed Won",
  "Closed Cancelled",
  "Written Confirmation of Award",
  "Proposal Sent",
  "Rebid Sent",
  "Closed Not Bidding",
  "On Hold by Client",
  "RFP In House",
  "RFI Sent",
  "WCA Rebid Sent",
  "Closed Lost",
  "RFI Requested",
  "Rebid In House",
  "WCA Cancelled",
  "Verbal Award",
  "WCA Rebid in House",
  "Verbal Award Rebid Sent",
  "Drafting Proposal",
  "Developing Project Scope",
];

// create a styled table cell with no horizontal padding
const StyledTableCell = withStyles((theme) => ({
  root: {
    padding: "5px",

    "&:first-child": {
      paddingLeft: "10px",
    },
  },
}))(TableCell);

const TableHead = ({ filters, setFilters, orderBy, order }) => {
  const classes = useStyles();
  const headCells = [
    { id: "projectId", label: "Project ID", type: "text" },
    { id: "studyName", label: "Study Name", type: "text" },
    { id: "sponsorName", label: "Sponsor", type: "text" },
    {
      id: "opportunityStage",
      label: "Opportunity Stage",
      type: "select",
      options: opportunityStageOptions,
    },
    {
      id: "enrollmentPlanExists",
      label: "Enrollment Plan Exists",
      type: "y_n",
    },
    { id: "soeExists", label: "SOE Exists", type: "y_n" },
    { id: "soeCosts", label: "SOE Costs", type: "y_n" },
    {
      id: "soeClinicalInformatics",
      label: "SOE Clinical Informatics",
      type: "y_n",
    },
    { id: "soeDataManagement", label: "SOE Data Management", type: "y_n" },
    {
      id: "enrollmentPlanLastUpdate",
      label: "Enroll Plan Last Update",
      type: "date",
    },
    { id: "soeLastUpdate", label: "SOE Last Update", type: "date" },
  ];

  const updateFilters = (id, value, type, startEnd) => {
    if ([type === "y_n" || type === "select"] && value === "all") {
      const filtersCopy = { ...filters };
      delete filtersCopy[id];
      return setFilters(filtersCopy);
    }

    if (type === "date") {
      let prevValue = filters[id] || {
        id: id,
        type: "date",
      };
      if (startEnd === "start") {
        prevValue = {
          ...prevValue,
          start: value,
        };
      } else if (startEnd === "end") {
        prevValue = {
          ...prevValue,
          end: value,
        };
      }
      return setFilters({
        ...filters,
        [id]: prevValue,
      });
    }

    setFilters({
      ...filters,
      [id]: {
        ...filters[id],
        value: value,
        type: type,
      },
    });
  };

  const inputComponents = {
    text: ({ id }) => (
      <TextField
        size="small"
        id={`${id}-text`}
        value={filters[id]?.value || ""}
        onChange={(e) => updateFilters(id, e.target.value, "text")}
        variant="outlined"
        InputLabelProps={{ classes: classes }}
        fullWidth
      />
    ),
    y_n: ({ id }) => (
      <TextField
        select
        size="small"
        id={`${id}-select`}
        value={filters[id]?.value ?? "all"}
        onChange={(e) => updateFilters(id, e.target.value, "y_n")}
        variant="outlined"
        fullWidth
      >
        <MenuItem value="all">All</MenuItem>
        <MenuItem value="Y">Yes</MenuItem>
        <MenuItem value="N">No</MenuItem>
      </TextField>
    ),
    select: ({ id, options }) => (
      <TextField
        select
        size="small"
        id={`${id}-select`}
        value={filters[id]?.value ?? "all"}
        onChange={(e) => updateFilters(id, e.target.value, "select")}
        variant="outlined"
        fullWidth
      >
        <MenuItem value="all">All</MenuItem>
        {options.map((option) => (
          <MenuItem
            key={option}
            value={option}
          >
            {option}
          </MenuItem>
        ))}
      </TextField>
    ),
    date: ({ id, type }) => (
      <CustomDatePickerRange
        selected={filters[id]?.start}
        onChange={(dates) => {
          const [start, end] = dates;
          const startDate = start ? new Date(start) : null;
          const endDate = end ? new Date(end) : null;
          if (startDate && !isNaN(startDate.getTime())) {
            updateFilters(id, startDate.getTime(), type, "start");
          }

          if (endDate && !isNaN(endDate.getTime())) {
            updateFilters(id, endDate.getTime(), type, "end");
          }
        }}
        startDate={filters[id]?.start ? new Date(filters[id]?.start) : null}
        endDate={filters[id]?.end ? new Date(filters[id]?.end) : null}
        selectsRange
        value={
          filters[id]?.start && filters[id]?.end
            ? [new Date(filters[id]?.start), new Date(filters[id]?.end)]
            : [null, null]
        }
        showYearDropdown
        showMonthDropdown
        useShortMonthInDropdown
        dateFormat={"dd/MMM/yyyy"}
        small={true}
      />
    ),
  };

  return (
    <MuiTableHead classes={classes}>
      <TableRow classes={classes}>
        <StyledTableCell>
          {Object.keys(filters).length > 0 && (
            <IconButton
              onClick={() => setFilters({})}
              variant="outlined"
              color="primary"
              size="small"
            >
              <ClearIcon />
            </IconButton>
          )}
        </StyledTableCell>
        {headCells.map(({ id, type, options }) => (
          <StyledTableCell
            key={id}
            align="center"
            padding="default"
            style={
              ["enrollmentPlanLastUpdate", "soeLastUpdate"].includes(id)
                ? { minWidth: "10rem" }
                : {}
            }
            sortDirection={orderBy === id ? order : false}
          >
            {inputComponents[type]?.({ id, options, type })}
          </StyledTableCell>
        ))}
      </TableRow>
    </MuiTableHead>
  );
};

export default TableHead;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#e6e7e8",
    borderRadius: "10px",
  },
}));
