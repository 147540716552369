import React, { useState } from "react";
import AddCountryModal from "./add.country.modal";
import AddCtisSubmissionModal from "./add.ctis.submission.modal";
import { ArrowUpward } from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import PublicIcon from "@material-ui/icons/Public";
import { Description as DescriptionIcon, GetApp as GetAppIcon } from '@material-ui/icons';
import { makeStyles } from "@material-ui/styles";
import styled from "styled-components";
import UploadPlanModal from "./add.bulk.countries.modal";
import { useExportPlan } from './export.plan/export.js';

const Controls = () => {
  const [openModal, setOpenModal] = useState(false);
  const [openSubmissionModal, setOpenSubmissionModal] = useState(false);
  const classes = useStyles();

  const handleSave = () => {};

  const handleSTISSave = () => {
    // Placeholder function for handling CTIS submission save action
    // window.location.reload();
  };

  const exportPlan = useExportPlan(); 

  const [fileUploadOpen, setFileUploadOpen] = useState(false);

  const handleFileUploadClose = () => {
    setFileUploadOpen(false);
    // Remove the page refresh here
  };

  const handleFileUploadContinue = () => {
    setFileUploadOpen(false);
    window.location.reload(); // Refresh the page only when "Continue" is clicked
  };

  const handleExportPlan = () => {
    exportPlan();
  };

  return (
    <Container>
      <Button color="primary" onClick={() => setFileUploadOpen(true)}>
        <DescriptionIcon classes={classes} /> UPLOAD PLAN
      </Button>
      <Button color="primary" onClick={handleExportPlan}>
        <GetAppIcon classes={classes} /> EXPORT PLAN
      </Button>
      <Button color="primary" onClick={() => setOpenModal(true)}>
        <PublicIcon classes={classes} /> Add Country
      </Button>
      <Button color="primary" onClick={() => setOpenSubmissionModal(true)}>
        <ArrowUpward classes={classes} />
        CTIS Submission
      </Button>

      {openModal && (
        <AddCountryModal
          open={openModal}
          onClose={() => setOpenModal(false)}
          onSave={handleSave}
        />
      )}

      {openSubmissionModal && (
        <AddCtisSubmissionModal
          open={openSubmissionModal}
          onClose={() => setOpenSubmissionModal(false)}
          onSave={handleSTISSave}
        />
      )}

      {fileUploadOpen && (
        <UploadPlanModal
          open={fileUploadOpen}
          onClose={handleFileUploadClose}
          onContinue={handleFileUploadContinue}
        />
      )}
    </Container>
  );
};

export default Controls;

// Styled component for the control panel container
const Container = styled.div`
  display: flex;
  justify-content: flex-end;
`;

// Custom styles for Material-UI components
const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: ".2em",
  },
}));
