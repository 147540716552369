import React from "react";
import { AccordionTable } from "../../../../../app/components";
import { useStudyPlan } from "../../../../../app/contexts/study.context";
import { COLUMN_HEADERS } from "./table.utils";

import TableCell from "@material-ui/core/TableCell";

const SummaryTable = ({ type }) => {
  const { simulationResults } = useStudyPlan();
  const { studyPlan: { studyPlanTypeDetail } } = useStudyPlan();
  const { summaryTable, summaryTableCohort } = simulationResults;

  const getColumnHeaders = (studyPlanTypeDetail) => {
    const baseHeaders = { ...COLUMN_HEADERS };
    if (studyPlanTypeDetail !== "Remodel (R)" && studyPlanTypeDetail !== "Remodel (D)") {
      delete baseHeaders["Screen Rate Go Forward"];
      delete baseHeaders["Enrollment Rate Go Forward"];
    }
    return baseHeaders;
  };

  return (
    <AccordionTable
      width={"100%"}
      summary={type === "cohort" ? "Summary - Cohort" : "Summary"}
      columns={getColumnHeaders(studyPlanTypeDetail)}
      defaultExpanded
      data={type === "cohort" ? summaryTableCohort : summaryTable}
      renderRow={(item) => (
        <>
          <TableCell component="th" scope="row">
            <strong>
              {type === "cohort" ? item.cohortName : item.siteGroupName}
            </strong>
          </TableCell>
          <TableCell align="center" style={{ whiteSpace: "nowrap" }}>
            {item["Enrollment Start"] ?? "DD/MMM/YYYY"}
          </TableCell>
          <TableCell align="center">
            {item["Number of Activated Sites"] ?? "##"}
          </TableCell>
          <TableCell align="center">{item["Screen Rate"] ?? "##"}</TableCell>
          {(studyPlanTypeDetail === "Remodel (R)" || studyPlanTypeDetail === "Remodel (D)") && (
            <TableCell align="center">{item["Screen Rate Go Forward"] ?? "##"}</TableCell>
          )}
          <TableCell align="center">
            {item["Enrollment Rate"] ?? "##"}
          </TableCell>
          {(studyPlanTypeDetail === "Remodel (R)" || studyPlanTypeDetail === "Remodel (D)") && (
          <TableCell align="center">
              {item["Enrollment Rate Go Forward"] ?? "##"}
            </TableCell>
          )}
          <TableCell align="center">
            {item["Number of Patients Screened"] ?? "##"}
          </TableCell>
          <TableCell align="center">
            {item["Number of Patients Enrolled"] ?? "##"}
          </TableCell>
          <TableCell align="center">
            {item["Number of Patients Completing Treatment"] ?? "##"}
          </TableCell>
          <TableCell align="center">
            {Number(item["Enrollment Duration"]).toFixed(1) ?? "##"}
          </TableCell>
          <TableCell align="center">
            {calculateScreenFailRate(item) ?? "##"}
          </TableCell>
          <TableCell align="center">
            {calculateDropOutRate(item) ?? "##"}
          </TableCell>
          <TableCell align="center">{item["Site Months"] ?? "##"}</TableCell>
        </>
      )}
    />
  );
};

export default SummaryTable;

export const calculateScreenFailRate = (item) => {
  return formatRate(
    (item["Number of Patients Screened"] -
      item["Number of Patients Enrolled"]) /
      item["Number of Patients Screened"]
  );
};

export const calculateDropOutRate = (item) => {
  return formatRate(
    (item["Number of Patients Enrolled"] -
      item["Number of Patients Completing Treatment"]) /
      item["Number of Patients Enrolled"]
  );
};

const formatRate = (rate) => Number(rate.toFixed(2));
